@import "~ng-pick-datetime-ex/assets/style/picker.min.css";
/*=================================================
  These are predefined variables for global usage
==================================================*/
$primary: #1d7dea;
$success: #30be76;
$green: #15cf5f;
$alert: #ffcc00;
$error: #f21d1d;
$red: #f21d1d;
$black: #333;
// $lightblack: #666;
$darkgray: #999;
$lightgray: #666;
$gray: #d2d2d2;
$gray1: #e8e8e8;
$white: #fff;
$lightwhite: #f7f7f7;
$purple: #9f37ae;
$seaGreen: #609b93;
$spoltlessRed: #ff7675;
$beauBlue: #cae5ff;
$lightBlue: #e9f4ff;
$lightgray1: #dfdfdf;
$primaryGradient: linear-gradient(180deg, #2594f5 -12.92%, #0b5fcb 53.75%);
$yellowGradient: linear-gradient(180deg, #ff850b 0%, #ffbf45 100%);
$redGradient: linear-gradient(
  180deg,
  #fa4f4f 0%,
  rgba(250, 153, 79, 0.58) 100%
);
$greenGradient: linear-gradient(180deg, #0bb382 0%, #25ceda 100%);
$lightblueGradient: linear-gradient(90deg, #2e93fb 0%, #29cad1 116.01%);
$primaryHover: #0366d7;

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
}

body {
  // min-height: 100dvh; (commented because of MV4-11159)
  font-family: "Nunito", sans-serif;
  overflow: revert !important;
  position: relative;
  overscroll-behavior: contain;
  // padding-top: 76px;
}

a {
  color: #1d7dea;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999999;
  opacity: 1;
  font-size: 14px;
  /* Firefox */
}
.mar-top-set {
  margin-top: 124px;
  @media (min-width: 768px) and (max-width: 992px) and (orientation: portrait) {
    margin-top: 130px;
  }
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999999;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999999;
}

/*for underline on anchor tags */
.text-underline {
  text-decoration: underline;
}

.container {
  max-width: 1170px;
  /*Overrides bootstrap container width*/
}

/*=================================================
  styles default input for gloabl usage
==================================================*/
input {
  border: 1px solid #dde2e5;
  border-radius: 4px;
  width: 100%;
  height: 44px;
  line-height: 44px;
  padding: 0 15px;
  outline: none;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
  }
}

/*=================================================
  error messages for gloabl usage
==================================================*/
.error {
  color: #f21d1d;
  text-align: left;
  font-size: 12px;
  line-height: 14px;
  margin-top: 9px;
  // custom error message
}

.success {
  text-align: left;
  font-size: 12px;
  line-height: 14px;
  margin-top: 9px;
  color: #30be76;
  // custom success message
}

/*=================================================
  Country flag styles for gloabl usage
==================================================*/
.flag-container {
  position: relative;
  display: grid;
  grid-template-columns: 25% 75%;
  width: 100%;
  border: 1px solid #dde2e5;
  border-radius: 4px;
  width: 100%;
  height: 44px;
  line-height: 44px;
  padding: 0;

  @media screen and (max-width: 768px) {
    grid-template-columns: 30% 70%;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 30% 70%;
  }

  .country-flag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
    background: no-repeat;
    border: none;

    &:focus {
      outline: none;
    }

    &:before {
      content: "";
      height: 25px;
      width: 1px;
      position: absolute;
      right: -5px;
      top: 10px;
      z-index: 9;
      background-color: #cbcbcb;
    }

    &:after {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #333;
    }

    .country-code {
      color: #999;
      padding-left: 4px;
      line-height: 20px;
    }

    img {
      width: 30px;
    }
  }

  .dropdown-menu.show {
    /*overide default bootstrap dropdown list top positioning*/
    top: -10px !important;
    border: none;
    width: 100%;
    max-height: 225px;
    overflow-x: hidden;
    overflow-y: scroll;

    .dropdown-item {
      border: 1px solid #dde2e5;
      padding-left: 10px;

      img {
        width: 30px;
      }
    }
  }

  .dropdown-item {
    .country-code {
      color: #999;
      padding-left: 6px;
      padding-right: 11px;
      width: 46px;
      display: inline-block;

      span {
        color: #333;
      }
    }
  }

  input {
    width: 100%;
    border: none;
    height: 42px;

    // padding-left: 25px;
    &:focus {
      outline: none;
    }

    @media screen and (max-width: 768px) {
      padding-left: 15px;
      font-size: 14px;
    }
    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 14px;
    }
  }
}

/*=================================================
  Class select dropdown styles for gloabl usage
==================================================*/
.mypat-dropdown {
  button {
    background-color: #fff;
    border: 1px solid #dde2e5;
    border-radius: 4px;
    width: 100%;
    height: 44px;
    line-height: 44px;
    padding: 0 30px 0 15px;
    -webkit-appearance: none;
    background: url("../src/assets/images/arrow-down.png") no-repeat;
    background-position: 97% 18px;
    color: #212529;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.active {
      color: #333;
    }

    &.disabled {
      background-color: rgba(153, 153, 153, 0.15);
      color: #9a9fa8;
      opacity: inherit;

      &:hover {
        background-color: rgba(153, 153, 153, 0.15);
        color: #9a9fa8;
      }
    }

    &:hover {
      background-color: transparent;
    }

    &:focus {
      box-shadow: none;
      outline: 0px;
    }

    &:after {
      border: none;
    }
  }
  .dropdown-menu.show {
    top: -3px !important;
    border: none;
    width: 100%;
    max-height: 225px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0;
    line-height: 34px;
    border-bottom: 1px solid #dde2e5;

    .dropdown-item {
      border: 1px solid #dde2e5;
      padding-left: 10px;
      border-top: none;
    }
  }
}

/*=================================================
  Submit button styles for gloabl usage
==================================================*/
button.btn-primary {
  background-color: #1d7dea;
  text-transform: capitalize;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  height: 44px;
  line-height: 44px;
  padding: 0;
  width: 100%;

  &:focus {
    box-shadow: none;
  }
}
.textButton {
  background-color: #fff;
  text-transform: capitalize;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  height: 44px;
  line-height: 44px;
  padding: 0;
  width: auto;
  color: #1d7dea;

  &:focus {
    box-shadow: none;
  }
}

.button-group {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    /* justify-content: flex-end;*/
  }

  button {
    max-width: 132px;
    width: 100%;

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  .btn-previous {
    background: none;
    color: #1d7dea;
  }
}

.disable-previous,
.disable-next {
  opacity: 0.5;
  cursor: not-allowed !important;
  text-decoration: none;
}

.onboarding-form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 280px;
  width: 100%;
  margin-top: 24px;

  @media screen and (max-width: 768px) {
    position: fixed;
    width: 100%;
    bottom: 0;
    max-width: 100%;
    left: 0;
    padding: 10px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    background: #fff;
  }
}

.btn-primary {
  &:not(:disabled) {
    &:not(.disabled).active {
      color: #fff;
      background-color: #1d8dea;
      border-color: #1d8dea;
    }

    &:not(.disabled) {
      &:active {
        color: #fff;
        background-color: #1d8dea;
        border-color: #1d8dea;
      }
    }
  }
}

.show {
  > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1d8dea;
    border-color: #1d8dea;
  }
}

/*===================================================
  Overrides bootstrap 'text-muted' class
=====================================================*/
.text-muted {
  color: #999;
  font-weight: normal;
  line-height: 22px;
}

/*===================================================
 Helper classes for font weight
=====================================================*/
.w-300 {
  font-weight: 300;
}

.w-400 {
  font-weight: 400;
}

.w-600 {
  font-weight: 600 !important;
}

.c {
  font-weight: 700;
}

.w-800 {
  font-weight: 800;
}

.w-900 {
  font-weight: 900;
}
.mr25 {
  margin-right: 25px;
}
.mr15 {
  margin-right: 15px;
}
.radio-container{
  display: flex;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #dfdfdf;
    position: absolute;
    top: 0;
    left: 4px;
    &:after{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #1d7dea;
      position: absolute;
      top: 4px;
      left: 4px;
      display: none;
      content: "";
    }
  }
  input:checked + .checkmark{
    background-color: #ffffff;
    border: 1px solid #1d7dea;
    &:after{
      display: block;
      background-color: #1d7dea;
    }
  }
  &:hover .checkmark{
    border-width: 2px !important;
    &:after{
      display: block;
      background-color: #DDE2E5;
      top: 3px;
      left: 3px;
    }
  }
}

/* custom checkbox */
.checkbox-container{
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
  input{
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark{
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #dde2e5;
    border-radius: 3px;
    &:after{
      width: 6px;
      height: 12px;
      position: absolute;
      left: 6px;
      top: 1px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
      content: "";
    }
  }
  input:hover + .checkmark{
    background-color: #FFFFFF;
    &:after{
      border-color: #E6E6E6;
      opacity: 1;
    }
  }
  input:checked + .checkmark,
  input:hover:checked + .checkmark{
    background-color: #1d7dea;
    &:after{
      border-color: #FFFFFF;
      opacity: 1;
    }
  }
}
/* //custom checkbox */

/* override bootstrap primary button background color */
  .btn-primary:not(:disabled),
  .btn.done-btn,
  #changePasswordModal .modal-footer .btn-close:not(:disabled),
  #logoutModal .modal-footer .btn-close,
  .createTaskFooter .saveBtn,
  .modalClearall .modal-dialog .modal-content .modal-footer .btn.btn-button,
  #regenModal .btn-update,
  .post-button button.btn-blue-default,
  .delete-header button.btn-blue-default,
  .cta .btnSubmit:not(:disabled) {
    background-color: #1d7dea;
    border-color: #1d7dea;
    transition: all .5s ease-in-out;
    &:hover{
      background-color: #146DD2 !important;
    }
  }
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }

  .btn-previous:not(:disabled):not(.disabled).active:focus,
  .btn-previous:not(:disabled):not(.disabled):active:focus,
  .show > .btn-previous.dropdown-toggle:focus {
    box-shadow: none;
  }

.btn:focus,
button:focus {
  outline: 0;
  box-shadow: none;
}
.btn:disabled {
  cursor: not-allowed;
}
#logoutModal .modal-footer .btn-footer {
  line-height: 26px;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 6px 20px;
  &:hover {
    border-color: var(--primaryBlueLight);
  }
}
/*
.modal.fade .modal-dialog {
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  transform: scale(0.5);
  opacity: 0;
}

.modal.fade.show .modal-dialog {
  opacity: 1;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  transition: all 0.6s;
}
*/
.modal.fade .modal-dialog.modal-dialog-zoom {
  -webkit-transform: translate(0, 0) scale(0.5);
  transform: translate(0, 0) scale(0.5);
}
.modal.show .modal-dialog.modal-dialog-zoom {
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
}
.modal-dialog-scrollable {
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    // background: #555;
  }
}
/*===================================================
Goal Setting 
=====================================================*/
.errorBorder {
  border-color: #f21d1d;
}
.goalSettingHeader {
    background-color: #fff;
    padding: 18px 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  @media (min-width: 320px) and (max-width: 767px) {
  }
  span.whiteColor {
    margin-left: 10px;
  }
  /*
  .NoOfsteps {
    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      padding-left: 30px;
    }
  }*/
  .dueDate {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 12px;
      font-weight: 500;
      color: #333;
    }
  }
}
.whiteColor {
  color: #fff;
}

/****************************** Goal CTA ******************************/
/**********************************************************************/

.goalSettingSteps {
  margin-top: 27px;
}
.mainheading {
  font-size: 28px;
  color: #333;
  vertical-align: middle;
  img {
    margin-right: 10px;
    vertical-align: revert;
  }
  &:hover {
    color: #333;
    text-decoration: none;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 20px;
  }
  &.mt22 {
    margin-top: 22px;
  }
}
.stepsCard {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin: 24px 0 70px;
  position: relative;
  @media (min-width: 320px) and (max-width: 767px) {
    margin-bottom: 75px;
    padding: 20px 5%;
  }
}
.globalOuterSpacing {
  padding: 30px 10%;
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0;
  }
}
.stepsHeading {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
  &.mt30 {
    margin-top: 30px;
  }
  &.mtb30 {
    margin: 30px 0;
    @media (min-width: 320px) and (max-width: 767px) {
      margin: 0px 0 10px 0;
    }
  }
  &.mt7 {
    margin-top: 7px;
  }
  &.mb-32 {
    @media (min-width: 320px) and (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
}
.smallHeading {
  font-size: 16px;
  line-height: 20px;
  color: #666;
  font-weight: 400;
  margin-top: 8px;
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
  }
}
.formHeading {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #333;
  margin-bottom: 24px;
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
  }
  &.mt71 {
    margin-top: 71px;
  }
}
.borderCard {
  background: #ffffff;
  border: 1px solid rgba(153, 153, 153, 0.3);
  border-radius: 8px;
  padding: 14px 16px;
  margin-bottom: 15px;
}
.posRelative {
  position: relative;
}
.GoalCTA {
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12);
  padding: 14px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
  left: 0;
  right: 0;
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 6px 0;
  }
  .GetStart {
    text-align: center;
    button {
      padding: 0 30px;
      width: auto;
    }
  }
  .GetNext {
    text-align: right;
  }
}
.link {
  text-align: left;
  padding: 0;
  color: #1d7dea;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    text-decoration: none;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    text-align: center;
    margin: 0 0 15px 0;
    font-size: 14px;
  }
}

#userMobile {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}
/***************Goal setting modal content format *********************/
/**********************************************************************/
.goalsettingModal {
  // .modal-body.padNew{
  //   padding: 30px 110px !important;
  // }
  .modal-body {
    text-align: center;
    padding: 40px 47px;
    @media (max-width: 768px) {
      padding: 20px 10px;
    }

    b {
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      color: #333;
      display: block;
      padding: 15px 0;
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
    p {
      font-size: 16px;
      line-height: 22px;
      color: #666;
      display: block;
      margin: 0;
      @media (max-width: 768px) {
        font-size: 14px;
      }
      span {
        font-weight: 700;
      }
    }
    .goalBold span {
      font-weight: 600 !important;
    }
    a.modalLink {
      font-size: 16px;
      font-weight: 500;
      margin: 25px 0 25px 0;
      display: block;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  .modal-footer {
    border: 0;
    padding: 15px 0 0 0;
    text-align: center;
    display: block;
    @media (max-width: 768px) {
      padding: 10px 0 0 0;
    }
    .btn-primary,
    .textButton {
      width: auto;
      padding: 0 30px;
      @media (max-width: 768px) {
        padding: 0 20px;
      }
    }
  }
}
.no-padding.alert {
  padding: 0 !important;
}
.nounderline:hover,
.nounderline:focus {
  text-decoration: none;
}
.relative_c {
  position: relative;
}
// Progress Bar
$ligit_green: #45c484;
#progressbar {
  margin: 0 0 30px;
  padding: 0;
  counter-reset: step;
  display: flex;
  justify-content: space-between;
  @media (max-width: 700px) {
    display: none;
  }
  li {
    list-style-type: none;
    color: $lightgray;
    font-size: 16px;
    position: relative;
    text-align: center;
    line-height: 20px;
    width: 100%;
    strong {
      font-weight: 400;
      max-width: 95px;
      display: block;
      margin: auto;
    }
    span {
      margin-bottom: 20px;
      display: block;
      position: relative;
      &:before {
        content: counter(step);
        counter-increment: step;
        width: 35px;
        height: 35px;
        font-size: 16px;
        color: $black;
        background: $gray1;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        -ms-border-radius: 50px;
        -o-border-radius: 50px;
        border-radius: 50px;
        margin: 0 auto;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        position: relative;
        transition: all 0.5s ease-out;
      }
      &:after {
        content: "";
        width: 100%;
        height: 2px;
        background: $gray1;
        position: absolute;
        left: 50%;
        top: 0;
        z-index: 0;
        bottom: 0;
        margin: auto;
        transition: all 0.5s ease-out;
      }
    }
    &.active span:before {
      background: $primary;
      color: $white;
    }
    &:last-child span:after {
      content: none;
    }
    &.active {
      color: $primary;
    }
    &.active:before {
      content: "";
      width: 50px;
      height: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      -o-border-radius: 50px;
      border-radius: 50px;
      border: 2px solid $primary;
      display: block;
      top: -7px;
      position: absolute;
      left: 0px;
      right: 0;
      margin: auto;
      background: $white;
      z-index: 1;
      transition: all 0.5s ease-out;
    }

    &.active.success_this span:after {
      height: 4px;
    }
    &.active.success_this span:before,
    &.active.success_this span:after {
      background: $ligit_green;
      transition: all 0.5s ease-out;
    }
    &.active.success_this {
      color: $lightgray;
      transition: all 0.5s ease-out;
    }
    &.active.success_this:before {
      content: none;
    }
  }
}
// goal setting global footer change
.GetStart .mr25 {
  margin-right: 10px;
}

.small-modal {
  .modal-content {
    border-radius: 10px;
  }
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 450px;
      margin: 1.75rem auto;
    }
  }
  .modal-header {
    padding: 20px 20px 0 24px;
    border-bottom: 0px none;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 20px 20px;
    @media screen and (max-width: 767px) {
      padding: 30px 15px 15px;
    }
    img {
      margin-bottom: 20px;
    }
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #333;
      margin-bottom: 0;
      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.2px;
      color: #666;
      padding-top: 11px;
      margin-bottom: 0px;
      text-align: center;
      @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .modal-footer {
    border-top: 0px none;
    padding: 0px 20px 30px;
    &.single-btn {
      justify-content: center;
    }
    .textButton {
      background-color: #fff;
      text-transform: capitalize;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      text-align: center;
      height: 44px;
      line-height: 44px;
      padding: 0;
      width: auto;
      color: #1d7dea;
      &:focus {
        box-shadow: none;
      }
    }
    .btn-primary,
    .textButton {
      width: auto;
      padding: 0 30px;
      @media (max-width: 768px) {
        padding: 0 20px;
        font-size: 14px;
        line-height: 36px;
        height: 36px;
      }
    }
  }
}

/**************theme css ******************/
:root {
  --primaryBlueLight: #1d7dea;
  --primaryBlueDarkMode: #3dabff;

  --primaryTxtColorLight: #333333;
  --primaryTxtColorDarkMode: #ffffff;

  --secondaryTxtColorLight: #666666;
  --secondaryTxtColorDarkMode: #ffffff;

  --thirdTxtColorLight: #999999;
  --thirdTxtColorDarkMode: #ffffff;

  --grayColor: #F4F4F4;
  --darkBlueColor: #146DD2;
  --lightGrayB6: #b6b6b6;
  --red: #EA0000;
  --borderColor: #f3f3f3;
  --warningColor: #ffc555;
  --warningColor2: #FFF5E5;
}

/************Darkmode header*********/
.modal-backdrop.show {
  opacity: 0.7;
}
// Modal
#casualAttemptDialog,
#casualAttemptDialogMob,
#accept_terms {
  // height: calc(100% - 150px);
  // overflow: hidden;
  .modal-body {
    padding: 32px 40px 26px 40px;
    .close {
      position: absolute;
      right: 15px;
      top: 15px;
      color: #999;
      opacity: 1;
      font-size: 36px;
      font-weight: 300;
    }
    h4 {
      font-size: 24px;
      font-weight: 600;
      color: #333;
      margin: 15px 0;
    }
    p {
      font-size: 16px;
      line-height: 22px;
      color: #666;
    }
    .width117 {
      width: 117px !important;
      margin: 0 auto;
    }
  }
}
// Custom scrollbar
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 12px;
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: #C6C6C6;
  /*outline: 1px solid #C6C6C6;*/
  border-radius: 12px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #C6C6C6;
  border-radius: 12px;
}
@-moz-document url-prefix() {
  .scroller {
    scrollbar-width: thin;
    scrollbar-color: #C6C6C6;
    border-radius: 12px;
  }
}
.animateFilter {
  transition: 0.5s;
  transform: translateX(0px) !important;
}
// myPlan Subject carousel CSS
.subjectCarousel {
  .owl-theme .owl-nav .owl-prev,
  .owl-theme .owl-nav .owl-next{
    min-width: 1.63rem;
    height: 6.5rem;
    position: absolute;
    left: 0px;
    top: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(270deg, rgba(190, 190, 190, 0.89) -64.71%, rgba(255, 255, 255, 0) 129.31%);
    margin: 0;
    @media(max-width: 767px){
      top: .88rem;
      display: none;
    }
    img {
      width: .75rem;
    }
  }
  .owl-theme .owl-nav .owl-next{
    left: auto;
    right: 0px;
  }
  .owl-theme .owl-nav .disabled {
    display: none;
  }
}

.scaleScreen {
  transform: scale(1.2);
  transform-origin: top center;
  @media (max-width: 767px) {
    transform: scale(1);
  }
}
.learnContentArea.scaleScreen {
  width: 80% !important;
  left: 0 !important;
  top: 0 !important;
  max-height: calc(100vh - 200px) !important;
  margin: 0 auto !important;
  transform: scale(1.2) !important;
  transform-origin: top center !important;
  @media (max-width: 767px) {
    transform: scale(1);
  }
}

body.modal-open {
  overflow: visible !important;
}
.QuestionText p{
  display: contents; 
  margin-bottom: 0;  
  img{
    min-width: auto;
    max-width: 100%;
    height: auto;
  }
}
.q-wrp p > p {
  margin: 0;
  margin-bottom: 0 !important;
}
.options{
  ul{
    li{
      span{
        p{
          margin-bottom:0;
        }
      }
    }
  }
}
.similarQuesList{
  ul{
    li{
      span{
        p{
          text-overflow: ellipsis;
          overflow: hidden;
          width: 97%;
          white-space: nowrap;
          margin-bottom:0;
          @media(max-width:767px){
            white-space: normal;
            margin-bottom: 0;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            //height: 41px;
          }
          img{
            @media(max-width:767px){
              width:150px;
            }
          }
        }
      }
    }
  }
}

//https://mypats.atlassian.net/browse/MV4-4721
@media(max-width:767px){
.questionArea{
  .questOptions{
    b{
      p{
        img{
          width: 100%;
        }
      }
    }
  }
}
}

svg > g[class^="raphael-group-"] > text{
  display: none !important;
}
.graph-container{
  position: relative;
}
.percentProficiency{
  position: relative;
}
.time-strategy-graph{
  position: relative;
}
.height-set-graph{
  position: relative;
}
.hide-trial-time{
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 7px;
  height: 38px;
  width: 300px;
  z-index: 9;
}
.hide-trial{
  background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 32px;
    width: 300px;
    z-index: 9;
}
textarea{
  outline: none;
}

.daterangepicker {
  z-index: 999999;
}

/* changes the daterangepicker background color of button */
.daterangepicker td.active, .daterangepicker .btn-primary {
  background-color: #1D7DEA !important;
  width: 32px;
  height: 32px;
}

.vertical-center{
  display: flex !important;
  align-items: center !important;
}

// Times up modal for test engnine
#timesUp{
  text-align: center;
  .modal-dialog{
    vertical-align: inherit;
    background: transparent;
    display: inline-flex;
    @media (min-width: 576px){
      min-width: 480px;
    }
    .modal-content{
      border-radius: 10px;
      padding: 25px 38px 30px;
      background: #fff;
      .modal-body{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        .middleSecon{
          h4{
            font-size: 24px;
            font-weight: 600;
            color: #000;
            margin: 20px 0;
          }
          p{
            font-size: 16px;
            font-weight: 400;
            color: #666;
            margin-bottom: 22px;
          }
        }
        .btn-primary{
          font-size: 16px;
          font-weight: 500;
          padding: 0 30px;
          border-radius: 4px;
          background-color: #1d7dea;
          text-transform: capitalize;
        }
      }
    }
  }
}
.questionDisplay img {
  max-width: 300px;
  max-height: 350px;
}
.DateContainer .date  {
  display: flex;
  justify-content: space-between; 
  @media (max-width:767px){
    flex-wrap: wrap;
  }
span {
  @media (max-width:767px){
    width: 34%;
  }
b{
  font-weight: 600;color: #333;display: block;
}
}
}
.submittext {
  &.spanlisttwo{
    justify-content: flex-start !important;
    span{
      width: 40%;
    }
  }
  span {
    color: #999 !important;
    @media(max-width:767px){
      font-size: 12px !important;
    }
    b{
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #666;
      @media(min-width:768px) and (max-width:1024px){
          display: block;
      }
      @media(max-width:767px){
        display: block;
        font-size: 12px !important;
      }
    }
  }
}
/* === mathjax CSS=== */
.matrix-ques-list {
  li p {
    @media (max-width: 767px) {
    overflow-y: auto;
    inline-size: 220px;
    }
    @media (min-width:768px) and (max-width: 1024px) {
      overflow-y: auto;
      inline-size: 330px;
      }
  }
}

.ML__mathlive {
  font-size: 1.1em;
}

.ML__mathlive .vlist {
  font-size: 1.05em;
}

.ML__mathlive {
  white-space: inherit;
}

.mathlive-header {
  text-align: center;
  margin-left: -48px;
  margin-right: -48px;
  border-bottom: solid 1px #9999;
}

.ML__error {
  display: none;
}
.static-content.dark-mode-mobile{
  padding-left: 20px;
  padding-right: 20px;
}
.dark-mode-mobile{
  background: #121212;
  color: #fff;
}
.dark-mode-mobile a{
  color: #fff !important;
}
.dark-mode-mobile b{
  color: #fff !important;
  font-weight: 800 !important;
}
.container.pa-0{
  padding: 0;
  .test-mode span{
    padding: 6px 0 !important;
    width: 60px;
    text-align: center;
  }
}
// Ripple effect
.livedot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  top: 50%;
  right: 0%;
  display: inline-block;
}

.livedot::before,.livedot::after {
  width: 150%;
  height: 150%;
  border-radius: 50%;
  background-color: rgba(250,74,74,.2);
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%,-50%);
  animation: sm_to_big 2s linear infinite;
  transition: all 0.5s ease-out;
  content: '';
  z-index: 1
}

.livedot::after {
  background-color: rgba(250,74,74,.1);
  animation: sm_to_big 2s linear 1s infinite;
  z-index: 2
}

@keyframes sm_to_big {
  from {
      width: 12px;
      height: 12px;
      background-color: rgba(250,74,74,.2)
  }

  to {
      width: 150%;
      height: 150%;
      background-color: rgba(250,74,74,0)
  }
}

@-webkit-keyframes sm_to_big {
  from {
      width: 12px;
      height: 12px;
      background-color: rgba(250,74,74,.2)
  }

  to {
      width: 150%;
      height: 150%;
      background-color: rgba(250,74,74,0)
  }
}

.livedot>span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #FA4A4A;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%,-50%);
  z-index: 3;
  display: inline-block;
}
.remove-device-modal.modal.show,
.remove-active-device-modal.modal.show {
  background-color: rgba(0, 0, 0, 0.5);
}
.pure-checkbox:hover input[type="checkbox"] + label:after{
  opacity: 1 !important;
  border-color: #E6E6E6 !important;
}
.mygoal-list .action-btn:not(:disabled):hover {
  color: var(--primaryBlueLight);
  img {
    content: url('assets/images/myGoal/history-icon-active.svg');
  }
}
.exam-modal .modal-body .btn-chip:not(.active):hover {
  background-color: rgba($color: #1d7dea, $alpha: 0.10) !important;
}
.selectTargetExam .contentType .contentCard:hover {
  box-shadow: 0px 5px 12.40px 0px #0000001F !important;
}
.concept-container .concept-card .phase-title:hover::before,
.weekExpand .card .card-header a .phase-title:hover::before,
.swot-tabmenucontent .card-header a:hover::before {
  background-color: var(--grayColor);
}
.commonCardView .cardSummary span img {
  transition: all 0.2s;
  &:hover {
    transform: scale(1.1);
    transition: all 0.2s;
  }
}
.commonCardView .conceptHistory .historyCard .Cta .viewResult {
  transition: all 0.3s;
  &:hover img {
    transform: translateX(50%);
    transition: all 0.3s;
  }
}
.conceptHistory-container ul li .btn-casusal:hover {
  background-color: rgba($color: #da3019, $alpha: 0.10);
}
.weekExpand .card .card-header a .phase-title::before {
  width: 22px;
  height: 22px;
  padding-right: 0;
  margin-right: 10px;
}
/* Tab Color */
.syllabus-container .syllabus-header .syllabus-header-right div:not(.active-button):hover,
.swot-headerContainer .tab-items .nav-item .nav-link:not(.active):hover,
.progress-index-detail-container .progress-headerContainer .tab-items .nav-item .nav-link:not(.active):hover,
.coverage-option-container .coverage-option:not(.selected):hover {
  color: var(--secondaryTxtColorLight) !important;
}
.headerPanel .tabPanel .tab:not(.active):hover {
  color: var(--primaryTxtColorLight) !important;
}
.applyFilter .textButton {
  margin-right: 5px !important;
  height: 36px;
  line-height: 1;
  padding: 5px 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  &:hover {
    border-color: var(--primaryBlueLight);
  }
}
.applyFilter .btn-primary-outline:hover {
  background-color: rgba($color: #1d7dea, $alpha: 0.10);
}
.footer-conceptdata .btn-primary:not(:disabled):hover,
.footer-conceptdata .btn-primary:not(.disabled):hover {
  background-color: var(--darkBlueColor) !important;
  border-color: var(--darkBlueColor) !important;
}
/* input hover */
#basicDetailPopup .modal-dialog .basicDetailsPopup .form-group input.form-control:hover,
#basicDetailPopup .modal-dialog .basicDetailsPopup .dropdown .btn-goalList[aria-expanded=false]:hover,
.createTaskContainer .form-group input.form-control:hover,
.createTaskContainer .columnstwo .time-input input:hover,
.dropdown .dropdown-toggle:hover,
textarea:hover,
.week_container .dropdown:hover,
#changePasswordModal .modal-body .change-password-form input:hover {
  border-color: var(--lightGrayB6) !important;
}
/* input focus */
#basicDetailPopup .modal-dialog .basicDetailsPopup .form-group input.form-control:focus ,
#basicDetailPopup .modal-dialog .basicDetailsPopup .form-group input.form-control:focus:hover,
#basicDetailPopup .modal-dialog .basicDetailsPopup .dropdown .btn-goalList[aria-expanded=true],
.createTaskContainer .form-group input.form-control:focus,
.createTaskContainer .form-group input.form-control:focus:hover,
.dropdown.show .dropdown-toggle,
textarea:focus,
.week_container .dropdown.show:has(.dropdown-toggle[aria-expanded=true]),
#changePasswordModal .modal-body .change-password-form input:focus {
  border-color: var(--primaryBlueLight) !important;
}
.week_container .dropdown.show .dropdown-toggle {
  border: none !important;
}
textarea:focus {
  box-shadow: none !important;
}
.dropdown.show .dropdown-toggle[aria-expanded=false] {
  border: none !important;
}
#basicDetailPopup .modal-dialog .basicDetailsPopup .form-group,
.markAsDone {
  .switch input:not(:checked) + .slider {
    background-color: #DADEE3 !important;
    &:hover {
      background-color: #D0D2D5 !important;
    }
  }
  .switch input:checked + .slider:hover {
    background-color: var(--darkBlueColor) !important;
  }
}
.calendarView .myDpIconLeftArrow {
  transition: all 0.3s;
  &:hover {
    transform: translateX(-20%);
    transition: all 0.3s;
  }
}

.calendarView .myDpIconRightArrow {
  transition: all 0.3s;
  &:hover {
    transform: translateX(20%);
    transition: all 0.3s;
  }
}
.noPlanTask .callAction button:nth-child(1):hover {
  border: 1px solid var(--primaryBlueLight) !important;
  border-radius: 4px;
}
.noPlanTask .callAction button:nth-child(2):hover,
.calendarMonthCreate .createTaskLink:hover {
  background-color: var(--darkBlueColor) !important;
}
.only-checkbox label::after {
  top: -2px !important;
}
.addTaskcta{
  display: inline-flex;
  align-items: center;
  transition: all 0.3s;
  i {
    width: 18px;
    height: 18px;
    position: relative;
    display: inline-flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 20px !important;
    margin-right: 3px;
    transition: all 0.3s;
  }
  &:hover i {
    background-color: var(--primaryBlueLight);
    color: var(--primaryTxtColorDarkMode);
    transition: all 0.3s;
  }
}
.full-calendar-icon img {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.1);
    transition: all 0.3s;
  }
}
.timetablePanel .rightTab .weekly_daily_view .dw_tab:not(.active):hover {
  color: var(--primaryTxtColorLight) !important;
}
.classDate>img {
  background-color: #1D7DEA;
  border-radius: 4px;
  &:hover {
    background-color: var(--darkBlueColor);
  }
}
.dateStrip .dailyview .owl-nav .nav-btn.prev-slide img {
  transition: all 0.3s;
  &:hover {
    transform: translateX(-20%);
  }
}
.dateStrip .dailyview .owl-nav .nav-btn.next-slide img {
  transition: all 0.3s;
  &:hover {
    transform: translateX(-20%);
  }
}
.dailyView .dv_card .leftData .timetable em.link:hover,
.timetable em.link:hover {
  border-color: var(--primaryBlueLight) !important;
}
.dailyView .dv_card .cta {
  transition: all 0.3s;
  &:hover img {
    transform: translateX(20%);
    transition: all 0.3s;
  }
}
.btn.btn-outline:hover {
  background-color: rgba($color: #1d7dea, $alpha: 0.10) !important;
}
.quesAnsFooter .blueBtn:not(:disabled):hover {
  background-color: var(--darkBlueColor);
}
.tag-creator-button:hover {
  transition: all 0.3s;
  svg {
    transform: scale(1.2);
    transition: all 0.3s;
  }
}
.view-stats {
  img {
    transition: all 0.3s;
  }
  &:hover img {
    transform: scale(1.1);
    transition: all 0.3s;
  }
}
.btn-listView:hover {
  color: #666666 !important;
}
.learnHeader .likeSaveMore .ellipseMenu .moreEllipse:hover {
  filter: brightness(0.9) invert(0);
}
.questionAns .quesContentArea ul li:hover {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.20) !important;
}
.weeklyView .dateStrip .owl-nav .owl-prev,
.weeklyView .dateStrip .owl-nav .owl-next {
  img {
    transition: all 0.3s;
  }  
  &:hover img {
    transform: scale(1.3);
    transition: all 0.3s;
  }
}
.weeklyView .schedule .footerCta:hover {
  color: var(--darkBlueColor) !important;
}
.phase-info ul li:not(.active):hover {
  background-color: var(--grayColor);
  color: var(--secondaryTxtColorLight) !important;
  border-radius: 50%;
}
.learnFooter .paginationBar .btnLeft:not(.disabled),
.learnFooter .paginationBar .btnRight:not(.disabled) {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.1);
    transition: all 0.3s;
  }
}
.learnFooter .maximizeIcon {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.2);
    transition: all 0.3s;
  }
}
.learnFooter .minimizeIcon {
  transition: all 0.3s;
  &:hover {
    transform: scale(0.9);
    transition: all 0.3s;
  }
}
.progressTrackerLink a.trackConcept, 
.progressTrackerLinkMob a.trackConcept {
  transition: all 0.3s;
  &:hover::after {
    transition: all 0.3s;
    transform: translateX(20%) rotate(-90deg) !important;
  }
}
.pendingDetails_container .filter_container .filter .filterCard ul li a:not(.active):hover {
  background-color: var(--grayColor);
  color: var(--primaryBlueLight) !important;
}
.subject-filter .dropdown-subject:not(.selected-subject):hover {
  background-color: var(--grayColor);
}
.dropdown-menu .dropdown-item:not(:active):hover,
.dropdown-menu .dropdown-item:not(.active-item):hover {
  background-color: var(--grayColor);
  color: inherit !important;
}
.dropdown-menu .dropdown-item.active-item:hover,
.dropdown-menu .dropdown-item.active:hover {
  color: var(--primaryBlueLight) !important;
}
.successIndexHead .btn-successList + .dropdown-menu {
  top: -2px !important;
}
.temparament-index-container .temparament-index .temparament-index-text .history-btn {
  transition: all 0.3s;
  &:hover {
    color: var(--darkBlueColor) !important;
    img {
      transform: scale(1.1);
      transition: all 0.3s;
    }
  }
}
.temperament-factors .tiles .tab-items .nav-item .temperamentCard:not(.active):hover {
  box-shadow: 0px 0px 10px 0px #00000040;
}
.time-management-container .header .cross,
.strategy-list .header .cross,
.confidence-list .header .cross {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.2);
    transition: all 0.3s;
  }
}
.searchFilter a img.filter-icon:hover,
.revisionListing .headerContainer .filterIcon img:hover {
  content: url(assets/images/hover-stage/filterAssign.svg);
}
.searchFilter a img.filtergrid-icon:hover,
.revisionListing .headerContainer .filtergrid-icon img:hover  {
  content: url(assets/images/hover-stage/gridicons_filter.svg);
}
.search-container .input-group:hover {
  input,button {
    border-color: var(--lightGrayB6) !important;
  }
}
.search-container .input-group input:focus {
  border-color: var(--primaryBlueLight) !important;
  &+.input-group-append button {
    border-color: var(--primaryBlueLight) !important;
  }
}
.dateRangeFilter .inputPosition input:hover, 
.dateRangeFilter .inputPosition1 input:hover,
.dateFilter .input-group input:not(:disabled):hover {
  border-color: var(--lightGrayB6) !important;
}
.dateRangeFilter .inputPosition input:disabled:hover, 
.dateRangeFilter .inputPosition1 input:disabled:hover {
  border-color: #dde2e5 !important;
}
.dateRangeFilter .inputPosition input:focus, 
.dateRangeFilter .inputPosition1 input:focus,
.dateRangeFilter .inputPosition:has(lib-angular-mydatepicker-calendar) input, 
.dateRangeFilter .inputPosition1:has(lib-angular-mydatepicker-calendar) input,
.dateRangeFilter .inputPosition:has(lib-angular-mydatepicker-calendar) input:hover, 
.dateRangeFilter .inputPosition1:has(lib-angular-mydatepicker-calendar) input:hover,
.dateFilter .input-group:has(lib-angular-mydatepicker-calendar) input:not(:disabled),
.dateFilter .input-group:has(lib-angular-mydatepicker-calendar) input:not(:disabled):hover {
  border-color: var(--primaryBlueLight) !important;
}
.filterFooter .textButton {
  margin: 0 5px;
  &:hover {
    border: 1px solid var(--primaryBlueLight);
    border-radius: 4px;
    color: var(--primaryBlueLight);
  }
}
.testListing .actionCTA .viewResult {
  img {
    transition: all 0.3s;
  }
  &:hover img {
    transform: translateX(50%);
    transition: all 0.3s;
  }
}
#dragItem:hover::after {
  background-color: var(--darkBlueColor) !important;
}
.lock-modal-btn img {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.2);
    transition: all 0.3s;
  }
}
#performance-tab li a:not(.active):hover{
  color: var(--secondaryTxtColorLight) !important;
}
.accuracy-dropdown {
  .dropdown-toggle:hover {
    border-color: transparent !important;
  }
  &:hover {
    border-color: var(--lightGrayB6) !important;
  }
  .dropdown-toggle[aria-expanded=true] {
    border-color: transparent !important;
  }
  &.show {
    border-color: var(--primaryBlueLight) !important;
  }
} 
.container:hover input ~ .checkmark::after {
  display: block;
  opacity: 1;
  border-color: #E6E6E6 !important;
}
.container:hover input ~ .checkmark {
  border-color:#cacaca !important;
}
#repQuesSol .panel:hover {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2) !important;
}
.subjecttag:not(.active):hover {
  background-color: var(--grayColor);
}
.swot-tabmenu .tab-menuitems .nav-item a:not(.active) {
  &::after {
    content: "";
    border-radius: 0px 0px 4px 4px;
    width: 0;
    height: 5px;
    background: #1d7dea;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all 0.3s;
  }
  &:hover::after {
    width: 100%;
    transition: all 0.3s;
  }
}
.floating-attempt .btn-primary:hover {
  background-color: var(--primaryTxtColorDarkMode) !important;
  border-color: var(--primaryTxtColorDarkMode) !important;
}
.back_dashboard:hover {
  background-color: rgba($color: #1d7dea, $alpha: 0.10);
  border-color: var(--primaryBlueLight) !important;
}
.selectExam .contentCard:hover {
  box-shadow: 0px 0px 10px 0px #0000001F;
  border-color: transparent !important;
}
.listing-container .card-body .listing-data .star-tag:hover svg path {
  fill: var(--primaryBlueLight)
}
.edit-container .concept-heading .tab-items .nav-item .nav-link:not(.active) {
  color: var(--thirdTxtColorLight) !important;
  &:hover {
    color: var(--secondaryTxtColorLight) !important;
  }
}
.footer-conceptdata .addMoreConceptBtn:hover {
  background-color: rgba($color: #1d7dea, $alpha: 0.10) !important;
}
.footer-conceptdata p.btn-text {
  padding: 11px 20px;
  margin-right: 10px;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid var(--primaryBlueLight);
    border-radius: 4px;
  }
}
.footer-conceptdata .cancelBtn {
  padding: 10px 20px;margin-right: 10px;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid var(--primaryBlueLight);
    border-radius: 4px;
  }
}
.infocard-container .infocard .nav-item .nav-link:not(.active):hover {
  color: var(--primaryTxtColorLight) !important;
}
.edit-container .conceptListing .listing .list-data li:hover,
.listing-container .card-body .listing-data li:hover,
.upcomingPanel .upcomingTestCard .cardContent:hover  {
  box-shadow: 0px 10px 20px 0px #0000001A;
}
.createPlan-container .filter-container ul li a:not(.active):hover {
  background-color: var(--grayColor) !important;
  color: var(--primaryBlueLight) !important;
}
.attemptQues .headerContainer .select .dropdown .dropdown-toggle,
.attemptQues .headerContainer .select .dropdown .dropdown-toggle[aria-expanded=false] {
  border: 1px solid rgba(153, 153, 153, 0.5) !important;
}
.attemptQues .headerContainer .select .dropdown .dropdown-toggle[aria-expanded=false] {
  border-color: rgba(153, 153, 153, 0.5) !important;
}
.attemptQues .headerContainer .select .dropdown .dropdown-toggle:hover {
  border-color: var(--lightGrayB6) !important;
}
.attemptQues .headerContainer .select .dropdown .dropdown-toggle[aria-expanded=true] {
  border-color: var(--primaryBlueLight) !important;
}
.small-modal .modal-footer .textButton {
  padding: 0 30px !important;
  &:hover {
    border-color: var(--primaryBlueLight);
  }
}
.rightheading-container .searchContainer .search .searchIcon {
  transition: all 0.3s;
  &:hover {
    background-color: var(--primaryBlueLight) !important;
    transition: all 0.3s;
    img {
      filter: brightness(0) invert(1);
      transition: all 0.3s;
    }
  }
}
.custom-dropdown .checkbox-listing li:not(.selected-subject):hover {
  background-color: var(--grayColor);
}
.assignTest .grid .ActionCTA a:hover {
  text-decoration: underline !important;
  color: var(--primaryBlueLight) !important;
}
.targetExam .examCardOuter:not(.active):hover {
  border-color: var(--primaryBlueLight) !important;
}
.syllabus-container .collapse-card .chapter a .chapter-content:hover>img {
  background-color: var(--grayColor);
}
.infocard-container .infocard .nav-item a:not(.active):hover {
  color: var(--primaryTxtColorLight) !important;
}
.progressSubjectNav ul li a:not(.active):hover {
  opacity: 1 !important;
}
.issuesCount div {
  &:nth-child(1):hover {
    background: rgba(9, 105, 214, 0.1) !important;
  }
  &:nth-child(2):hover {
    background: rgba(41, 157, 22, 0.1) !important;
  }
  &:nth-child(3):hover {
    background: rgba(222, 132, 26, 0.1) !important;
  }
}
.helpReport .reportListTitle a:hover {
  color: var(--primaryBlueLight);
}
.reportListsHelp .accordion-group .accordion-inner .editFeedback .header .leftSec .btn-edit:hover,
.fedbackReopen .btn-text:hover,
.helpFilter .filter-container .filterCard .headingContainer .textButton:hover,
.addComment:hover,
.helpReport .reportListTitle a:hover,
.anchor-tag:hover,
.attempts-card-container .result-btn:hover,
.rate_section:hover,
.something:hover,
.attendance-listing .table tbody td .view-cta:hover,
.mainTestCard .phaseTestContainer .rightweek-container .btn:hover,
.personalinfo-heading.school-head.top-header a:hover,
.reward-container .label-name a:hover,
.packagelist-table tbody tr td a:hover {
  text-decoration: underline !important;
}
.helpFilter .filter-container .filterCard ul li a:not(.active):hover {
  background-color: var(--grayColor);
  color: var(--primaryBlueLight) !important;
  span {
    color: var(--primaryBlueLight) !important;
  }
}
.attempt-modal .modal-dialog .modal-content .modal-body .nav-tabs .nav-item .nav-link:not(.active):hover {
  color: var(--primaryTxtColorLight) !important;
}
.custom-form-checkbox:hover input:not(:checked) + span {
  background-image: url('assets/images/hover-stage/checkbox-tick.svg');
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center;
}
.attempt-modal .modal-dialog .modal-content .modal-body .btn-blue {
  transition: all .5s ease-in-out;
  &:hover{
    background-color: #146DD2 !important;
  }
}
.reportIssue .modal-body .dropdown-menu.show ul li:not(.borderTop):hover {
  background-color: var(--grayColor);
  label:hover {
    background-color: transparent !important;
  }
}
.profile-master-container #myTab li a:not(.active):hover {
  color: var(--secondaryTxtColorLight) !important;
}
.personalinfo-heading .edit-btn img {
  transition: all 0.3s;
  &:hover {
    transition: all 0.3s;
    content: url(assets/images/hover-stage/edit-icon.svg);
  }
}
.personalinfo-heading .cancel-btn {
  padding: 12px 10px;
  margin-right: 5px;
  border: 1px solid transparent;
  transition: all 0.3s;
  &:hover {
    border-color: var(--primaryBlueLight);
    transition: all 0.3s;
  }
} 
.personalinfo-container {
  .form-group input:not(:disabled) {
    &:hover {
      border-color: var(--lightGrayB6);
    }
    &:focus {
      border-color: var(--primaryBlueLight)
    }
  }
  .dropdown.flag-container {
    &:hover {
      border-color: var(--lightGrayB6);
    }
    &:has(.country-flag.dropdown-toggle[aria-expanded=true]),
    &:has(input:focus) {
      border-color: var(--primaryBlueLight);
    } 
  }
  .inputPosition:has(lib-angular-mydatepicker-calendar) input,
  .inputPosition:has(lib-angular-mydatepicker-calendar) input:hover {
    border-color: var(--primaryBlueLight);
  }
}
.dropdown.mypat-dropdown .btn.dropdown-toggle:hover {
  border-color: var(--lightGrayB6);
}
.mypat-dropdown .dropdown-menu.show {
  top: -2px !important;
}
.form-group.calendarView:has(lib-angular-mydatepicker-calendar) input {
  border-color: var(--primaryBlueLight);
}
.personalinfo-container .profile-photo-input {
  cursor: pointer;
  font-size: 0;
}
.personalinfo-container .uploadFile:hover {
  background-color: rgba($color: #1d7dea, $alpha: 0.10) !important;
}
.packageschedule-container .btn-syllabus {
  transition: all 0.3s;
  &:hover img {
    transform: scale(1.2);
    transition: all 0.3s;
  }
}
#changePasswordModal .modal-body .change-password-form {
  input[type=password] + .eye-icon img:hover {
    content: url(assets/images/hover-stage/clarity_eye-hide-line.svg);
  }
  input[type=text] + .eye-icon img:hover {
    content: url(assets/images/hover-stage/clarity_eye-line.svg);
  }
}
.profile-menu .dropdown-menu a.active {
  color: var(--primaryTxtColorDarkMode) !important;
  &:hover {
    background-color: var(--primaryBlueLight) !important;
    color: var(--primaryTxtColorDarkMode) !important;
  }
}
.heading-filter .selectFilter .dropdown button:hover {
  border-color: var(--lightGrayB6) !important;
}
.dropdown.show .dropdown-toggle[aria-expanded=true]:hover {
  border-color: var(--primaryBlueLight) !important;
}
.assignTest .grid:hover {
  box-shadow: 0px 10px 20px 0px #0000001A;
}
.graph-container .graph-heading button img,
.calendarContainer .heading-content .calendarCloseIcon {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}
.dailyView .dv_card:hover {
  box-shadow: 0px 10px 20px 0px #0000001A;
}
.verifyEmail-modal .btn-text {
  border: 1px solid transparent;
  &:hover {
    border-color: var(--primaryBlueLight);
  }
}
.liveClasses_week .item .hiddenMob:hover {
  background-color: transparent !important;
  color: var(--primaryBlueLight);
}
.liveClasses_week .owl-theme .owl-nav .owl-prev img {
  transition: all 0.3s;
  &:hover {
    transform: translateX(-20%);
  }
}
.liveClasses_week .owl-theme .owl-nav .owl-next img {
  transition: all 0.3s;
  &:hover {
    transform: translateX(20%);
  }
}
.liveClasses_week .calendar-icon {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}
.pervLink button:hover span {
  text-decoration: underline;
}
.proficiency .progress-bar-container .progress-Text .isPending:hover,
.viewNotes .viewNotesCTA:hover {
  text-decoration: underline !important;
}
.progress-index-detail-container .listContainer .pendinglist .chapterListContainer .accordion-button:hover::before {
  background-color: var(--grayColor);
}
.heading-container .desktopSearch input[type="text"],
.searchbox input,
.searchPanel input[type="text"] {
  &:hover {
    border-color: var(--lightGrayB6) !important;
  }
  &:focus {
    border-color: var(--primaryBlueLight) !important;
  }
}
.revision_block:not(.select):hover {
  border-color: var(--primaryBlueLight);
  box-shadow: none !important;
}
.upcomingPanel .upcomingTestCard .cardContent .cardRight a:hover {
  text-decoration: underline !important;
}
.custom-dropdown-toggle {
  &:hover {
    border-color: var(--lightGrayB6) !important;
  }
  &[aria-expanded=true] {
    border-color: var(--primaryBlueLight) !important;
  }
}
.reportListsHelp .accordion-group:has(.accordion-toggle[aria-expanded=false]):hover,
.reportLists .accordion-group .accordion-body:has(.accordion-toggle[aria-expanded=false]):hover {
  box-shadow: 0px 10px 20px -10px #0000001A;
}
.carouselCalendar .slide:hover {
  i {
    color: var(--primaryBlueLight) !important;
  }
  b::before {
    content: "";
    width: 46px;
    height: 46px;
    border-radius: 100%;
    background: rgba($color: #1d7dea, $alpha: 0.10);
    position: absolute;
    z-index: -1;
    top: 36px;
    left: 24px;
    box-shadow: 0px 4px 10px rgba(46, 102, 231, 0.35);
  }
}
.dateStrip:not(:has(.weeklyview)) .day_date:not(.current):hover {
  color: var(--primaryBlueLight) !important;
  p {
    color: var(--primaryBlueLight) !important;
  }
}
.dropdown.notification-list button, .dropdown.profile-menu button {
  border: none !important;
}
#basicDetailPopup .modal-dialog .modal-footer.double-btn .textButton {
  border: 1px solid transparent;
  &:hover {
    border-color: var(--primaryBlueLight);
  }
}
.floating-attempt.show .dropdown-menu > li:hover {
  background-color: var(--grayColor);
}
.accuracy-dropdown .dropdown-menu {
  top: -14px !important;
  right: 8px !important;
  left: auto !important;
  width: 100%;
  &::before,
  &::after {
    display: none !important;
  }
}
.full-test .cross-button img,
.testdetail-container .close img,
.modal .modal-dialog .modal-content .close img,
.reportIssue .modal-header button.close,
#casualAttemptDialog .modal-body .close, #casualAttemptDialogMob .modal-body .close, 
#accept_terms .modal-body .close,
.custmofferpopup .close {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}
.quesType .dropdown .dropdown-menu .dropdown-item:hover .pure-checkbox input[type="checkbox"]:not(:checked) + label::after {
  transform: rotate(-45deg) scale(1);
  content: "";
  transition: transform 200ms ease-out;
  border-color: #E6E6E6;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: transparent !important;
}
.revisionMainMenu .subjectCarousel.owl-theme .owl-nav [class*=owl-]:not(.disabled) {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}
.subjectCarousel .owl-theme .owl-nav .owl-prev,
.subjectCarousel .owl-theme .owl-nav .owl-next {
  transition: all 0.3s;
  &:hover img {
    transform: scale(1.2);
    transition: all 0.3s;
  }
}
.daterangepicker .calendar-table td:not(.active, .today):hover {
  background-color: var(--grayColor);
  border-radius: 50%;
}
.calendarView .ng-mydp table td:not(.myDpSelectedDay):hover .myDpDayValue:not(.myDpMarkCurrDay) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.drp-calendar .calendar-table .prev, 
.drp-calendar .calendar-table .next {
  &:hover {
    background-color: var(--primaryBlueLight) !important;
    span {
      border-color: var(--primaryTxtColorDarkMode) !important;
    }
  }
}
.taskListFull li img,
.createTaskmainContainer .heading-container .editDeleteCTA img {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.2);
  }
}
.taskListFull li:hover,
.taskList li:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.12) !important;
}
.owl-dt-timer-content .owl-dt-timer-input {
  &:hover {
    border-color: var(--lightGrayB6) !important;
  }
  &:focus {
    border-color: var(--primaryBlueLight) !important;
  }
}
.owl-dt-control-arrow-button:not(:disabled) {
  &[aria-label='Previous month'] svg,
  &[aria-label='Next month'] svg {
    transition: all 0.3s;
  }
  &[aria-label='Previous month']:hover svg {
    transform: translateX(-20%);
  }
  &[aria-label='Next month']:hover svg {
    transform: translateX(20%);
  }
}
.createTaskFooter .cancelBtn {
  border: 1px solid transparent !important;
  border-radius: 4px;
  &:hover {
    border-color: var(--primaryBlueLight) !important;
  }
}
#changePasswordModal .modal-body .forget-password .btn:hover,
.regDevice-contaier .regDevice-panel .device-card .deviceStatus:hover {
  text-decoration: underline !important;
}
.searchbox .dropdown .btn.dropdown-toggle:hover,
.searchbox .dropdown .btn.dropdown-toggle:focus,
.searchbox .dropdown.show .btn.dropdown-toggle  {
  border-color: transparent !important;
  border-right-color: rgba(153, 153, 153, 0.2) !important;
}
.revisionMainMenu .menuCard {
  transition: all .5s ease-in-out;
  border: 1px solid transparent;
  &:not(.active):hover {
    border-color: var(--primaryBlueLight) !important;
  }
}
.navbar-nav a {
  &:hover {
    color: var(--primaryBlueLight) !important;
  }
}
.modalClearall .modal-dialog .modal-content .modal-footer .btn.btn-button {
  margin-left: 14px !important;
}
.modalClearall .modal-dialog .modal-content .modal-footer .btn.btn-text {
  padding: 10px 30px !important;
  border: 1px solid transparent !important;
  border-radius: 4px;
  &:hover {
    border-color: var(--primaryBlueLight) !important;
  }
}
.searchbox .searchListGlobal li:hover {
  background-color: var(--grayColor) !important ;
}
.searchbox.expand .crossIcon.animateCross img {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}
.attempt-modal .modal-dialog .modal-content .modal-body .close-icon,
.filterContainer .titleClose a img,
.filter .filter-container .filterHeader .titleClose a img {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.2);
  }
}
.rightheading-container .searchContainer .search input[type="text"].searchExpand,
.searchContainer .search input[type="text"].searchExpand,
.searchBox.form-control,
.tag-input-form input {
  &:hover {
    border-color: #b6b6b6 !important;
  }
  &:focus {
    border-color: var(--primaryBlueLight) !important;
    box-shadow: none;
  }
}
.searchContainer .search .searchIcon:hover {
  background-color: var(--primaryBlueLight) !important;
  img {
    filter: brightness(0) invert(1);
  }
}
.popularTagdiv .showMore:hover {
  text-decoration: underline;
}
.QuesDetailsHeader span .viewStats {
  img {
    transition: all 0.3s;
  }
  &:hover img {
    transform: scale(1.1);
  }
}
.quesDtlsFooter .container .prevSlide:not(:disabled) {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}
.quesDtlsFooter .container .nextSlide:not(:disabled):hover {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}
.cta .btnAttempt:hover {
  background: rgba(29, 125, 234, 0.1) !important;
}
.questionArea .questOptions .typeBookmark em {
  transition: all 0.3s;
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
}
#tagModal .filter-card:hover input:not(:checked) + .checkmark-cover .checkmark::after {
  left: 8px;
  top: 3px;
  width: 10px;
  height: 17px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: block;
}
.change-tag-container img,
#current_projection .modal-footer .currentProjectionText img,
.viewNotesModal .modal-header .close svg {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}
.create-new-container:hover {
  transition: all 0.3s;
  &:hover button {
    transform: scale(1.1);
  }
}
.delete-header button.btn-transparent-default {
  line-height: 18px;
}
.post-button button.btn-transparent-default,
.delete-header button.btn-transparent-default {
  border: 1px solid transparent;
  border-radius: 4px;
  margin: 0 5px;
  &:hover {
    border-color: var(--primaryBlueLight);
  }
}
.questionArea .questOptions .noOfQues .viewNotes:hover {
  text-decoration: underline;
}
.sampleFooter .pagingNotes .prevIcon:not(.disabled) {
  transition: all 0.3s;
  &:hover {
    left: -5px !important;
  }
}
.sampleFooter .pagingNotes .nextIcon:not(.disabled) {
  transition: all 0.3s;
  &:hover {
    right: -5px !important;
  }
}
.questOptions .cta .btnTxt {
  line-height: 21px !important;
  height: 42px !important;
  border: 1px solid transparent;
  &:hover {
    border-color: var(--primaryBlueLight);
  }
}
.mygoal-list .action-btn:not(:disabled):hover {
  color: var(--primaryBlueLight);
  img {
    content: url('assets/images/myGoal/history-icon-active.svg');
  }
}
.leftside-panel.small .leftmenu-container ul li{
  /*padding: .5rem 0.75rem !important;*/
  .nav-link .heading-container{
    display: none;
  }
}
.rightopencard-container.expand .success-index-stepper-block .stepperImg{
  transform: scale(1.3);
  transform-origin: left top;
  transition: all .5s ease-in-out;
  margin-left: 5rem;
  @media(max-width:1199px){
    transform: scale(1);
  }
}